/* Menu.css */
.rounded-button {
    display: inline-block;
    text-align: center;
    text-decoration: none;
    padding: 8px 16px;
    border-radius: 10px;
  }
  
  .login-button {
    background-color: #ff4eaa;
    color: white;
  }
  
  .signup-button {
    background-color: #1A237E;
    color: white;
    padding: 10px 16px;
  }