/* src/components/PopupDrawer.css */

.news-container {
    overflow: hidden;
    white-space: nowrap; /* Prevents the text from wrapping */
    margin-top: 20px; /* Adds some space above the news section */
}

.news-scroll {
    display: inline-block;
    animation: scroll 10s linear infinite; /* Adjust the duration as needed */
}

@keyframes scroll {
    0% {
        transform: translateX(100%); /* Start off screen to the right */
    }
    100% {
        transform: translateX(-100%); /* End off screen to the left */
    }
}
