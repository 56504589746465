.ai-screening-container {
    padding: 20px;
}

.call-to-action {
    margin-top: 20px;
    text-align: center;
}

.call-to-action h2 {
    margin-bottom: 10px;
}

.call-to-action p {
    margin-bottom: 20px;
}
