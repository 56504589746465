.footer {
    background-color: #696A71; /* Adjust as needed */
    color: white;
    padding: 20px 0;
}

.footer-content {
    margin: 0 auto;
    justify-content: center; /* Centers content horizontally */
    align-items: center; /* Centers content vertically */
    padding: 20px; /* Add padding for spacing */
    min-height: 200px; /* Optional: ensures there's enough height to see the centering effect */
}

.footer-title {
    color: white;
    text-decoration: underline;

}

.footer-text {
    color: rgba(255, 255, 255, 0.8);
}

.footer-links, .footer-assignments {
    list-style: none;
    padding: 0;
}

.footer-link {
    color: rgba(255, 255, 255, 0.8);
    text-decoration: none;
}

.footer-link:hover {
    text-decoration: underline;
}

.footer-assignments {
    padding: 0;
}

.assignment-image {
    width: 60px;
    height: 45px;
    background-size: cover;
    background-position: center;
    display: inline-block;
    margin-right: 10px;
}

.footer-bottom {
    background-color: #2e2f2f;
    padding: 10px 0;
    text-align: center;
}

.footer-bottom-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.footer-social {
    margin-left: auto;
}

.footer-social-link {
    color: rgba(255, 255, 255, 0.8);
    margin: 0 10px;
    text-decoration: none;
}

.footer-social-link:hover {
    text-decoration: underline;
}

/* Footer Component Styles */
.footer {
    background-color: #f0f2f5;
    padding: 20px 0;
}

.footer-content {
    padding: 0 20px;
}

.footer-title {
    color: #000;
    text-transform: uppercase;
}

.footer-links {
    list-style-type: none;
    padding: 0;
}

.footer-link {
    display: block;
    color: #1e1e1e;
    margin-bottom: 10px;
}

.footer-link:hover {
    text-decoration: underline;
}

.footer-bottom {
    background-color: #2e2f2f;
    color: #ffffff;
    text-align: center;
    padding: 10px 0;
}

.footer-bottom-content {
    max-width: 1200px;
    margin: 0 auto;
}

.footer-social {
    margin-top: 10px;
}

.footer-social-link {
    color: #ffffff;
    margin: 0 10px;
    text-decoration: none;
}

.footer-social-link:hover {
    text-decoration: underline;
}

.custom-padding-left {
    padding-left: 30px; /* Adjust the value as needed */
}
