/* src/index.css */
.layout {
  min-height: 100vh;
}

.logo {
  height: 31px;
  width: 120px;
  /*background: #fff;*/
  float: left;
}
.ant-form-item-explain {
  text-transform: lowercase;
  font-size: 12px;
  color: #ff4d4f; /* Customize the color as needed */
}