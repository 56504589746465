/* General Reset */
body, html {
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  font-family: 'Poppins', sans-serif; /* or 'Roboto', sans-serif */
}

/* Layout and Header */
.layout {
  min-height: 100vh;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #74797c;
  width: 100%;
  box-sizing: border-box;
}

.logo {
  width: 127px;
  height: 63px;
  background-size: contain;
  background-repeat: no-repeat;
}

/* Style for the logo image */
.logo-image {
  width: 100%;
  height: auto;
  display: block; /* Removes any unwanted space below the image */
  background: transparent; /* Ensures background is transparent */
}

.menu {
  flex: 2;
  display: flex;
  justify-content: flex-end;
}

.layout .ant-menu {
  line-height: 64px;
}

.layout .ant-menu-item {
  font-size: 12px;
  color: #fff; /* Ensure menu items are white */
}

/* Keep menu item text color white on hover and active */
.layout .ant-menu-item:hover,
.layout .ant-menu-item-active,
.layout .ant-menu-item-selected {
  color: #fff !important; /* Override the default color */
  background-color: transparent; /* Ensure no background color change */
}

.mobile-menu-button {
  display: none;
}

@media (max-width: 768px) {
  .mobile-menu-button {
    display: inline-block;
  }
  .menu {
    display: none;
  }
}

/* Menu Links */
.menu-link {
  color: #fff;
  font-size: 11px;
  transition: color 0.3s ease, background-color 0.3s ease;
  padding: 5px;
  display: inline-block;
  text-align: center;
  line-height: 20px;
}

.menu-link:hover {
  color: #e50325 !important;
  background-color: rgba(255, 255, 255, 0.1);
}

/* Sliders Section */
.sliders-section {
  position: relative;
  width: 100%; /* Ensure full width */
  height: 300px; /* Adjust height as needed */
  overflow: hidden; /* Hide anything that goes outside the box */
  background-color: #f0f2f5;
}

.carousel {
  width: 100%;
}

.carousel-slide {
  position: relative; /* Ensure content is positioned correctly */
  height: 300px; /* Full height of the parent container */
  text-align: center;
}

.slider-image {
  width: 100%; /* Ensure image takes full width */
  height: 100%; /* Ensure image covers the container */
  object-fit: cover; /* Maintain aspect ratio while covering the container */
}

.slider-content {
  position: absolute;
  bottom: 20%;
  left: 50%;
  transform: translateX(-50%);
  width: 80%;
  padding: 10px;
  z-index: 2;
  text-align: center;
  color: white;
}

.slider-content h2 {
  font-size: 2em; /* Adjust font size for larger screens */
  margin-bottom: 10px;
}

.slider-content p {
  font-size: 1.2em; /* Adjust font size for larger screens */
}

/* Responsive adjustments for mobile devices */
@media (max-width: 768px) {
  .sliders-section {
    height: 200px; /* Reduce height for mobile screens */
  }

  .carousel-slide {
    height: 200px; /* Match slide height to the container */
  }

  .slider-content {
    bottom: 10%; /* Adjust position for smaller screens */
    width: 90%; /* Increase width to fit better on small screens */
  }

  .slider-content h2 {
    /*font-size: 1.5em; !* Reduce font size for smaller screens *!*/
    font-size: 48px;
    font-weight: 500;
    text-shadow: 1px 1px 4px rgba(0, 0, 128, .5);
    letter-spacing: 0;
    margin-top: 32px;
    margin-bottom: 16px;
    color: #fff;
    font-family: RubikRegular, sans-serif;
  }

  .slider-content p {
    font-size: 1em; /* Reduce font size for smaller screens */
    color: rgba(255, 255, 255, 0.9);
    font-weight: 400;

  }
}


.slider-content p {
  color: #fff;
  font-family: Opensans-Regular, sans-serif;
}

@media (max-width: 480px) {
  .sliders-section {
    height: 150px; /* Further reduce height for very small screens */
  }

  .carousel-slide {
    height: 150px; /* Match slide height to the container */
  }

  .slider-content {
    bottom: 5%; /* Adjust position further for very small screens */
    width: 95%; /* Increase width to fit better on very small screens */
  }

  .slider-content h2 {
    font-size: 1.2em; /* Further reduce font size */
  }

  .slider-content p {
    font-size: 0.9em; /* Further reduce font size */
  }
}

@media (min-width: 768px) {
  .slider-content {
    width: 50%;
    bottom: 50%;
  }

  .slider-content h2 {
    font-size: 2em;

  }

  .slider-content p {
    font-size: 1.5em;
  }
}

@media (max-width: 767px) {
  .slider-content {
    width: 90%;
    bottom: 10%;
  }

  .slider-content h2 {
    font-size: 1.5em;
  }

  .slider-content p {
    font-size: 1em;
  }
}

/* Search Filter */
.search-filter-container {
  /*background-color: #303f9f;*/
  color: #ffffff;
  text-align: center;
  padding: 10px 0;
  height: 80px;
  position: relative; /* Adjusted from absolute to relative */
  z-index: 1;
}

.search-filter {
  background: rgba(255, 255, 255, 0.8);
  padding: 10px;
  max-width: 90%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 0 auto; /* Center the filter */
}

.search-filter input {
  width: 100%;
  margin-bottom: 10px;
}

.search-filter .ant-btn {
  width: 100%;
}

/* Main Content */
.main-content {
  padding: 20px; /* Adjust padding as needed */
  margin-top: 20px; /* Default margin for larger screens */
  box-sizing: border-box;
}

@media (max-width: 767px) {
  .main-content {
    /*margin-top: 130px; !* Increase margin to ensure there's space for the search filter *!*/
  }
}

.featured-subjects, .qa-tutoring {
  padding: 20px;
}

/* Post Question Button */
.post-question-btn {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  padding: 10px 20px;
  background-color: #1A237E;
  color: #fff;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-bottom: 16px;
}

.post-question-btn:hover {
  background-color: #3F51B5;
}

@media (max-width: 768px) {
  .post-question-btn {
    top: 15px;
    left: 15px;
  }
}

/* Content Container */
.content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

/* Additional Styles */
.indigo.darken-2 {
  background-color: #d6d9e6;
}

.white-text {
  color: #ffffff;
}

.center {
  text-align: center;
}

.search-section {
  background-color: #303f9f;
  color: #ffffff;
  text-align: center;
}

.search-section .container {
  max-width: 1200px;
  margin: 0 auto;
}

.btn-search {
  width: 100%;
  border-radius: 0 4px 4px 0;
}

.ant-select, .ant-input {
  border-radius: 4px;
}

.ant-form-item {
  margin-bottom: 0;
}

.ant-form-item-control-input {
  display: flex;
  align-items: center;
}

.ant-btn-primary {
  background-color: #1a237e;
  border-color: #1a237e;
}
p {
  margin-top: 0;
  margin-bottom: 1em;
  color: #504d4d;
  line-height: 20px;
}

:where(.css-dev-only-do-not-override-1c0na6j).ant-menu-light .ant-menu-item-selected, :where(.css-dev-only-do-not-override-1c0na6j).ant-menu-light > .ant-menu .ant-menu-item-selected {
  color: #000000;
}
