/* Main Content */
.main-content {
    padding: 20px; /* Adjust padding as needed */
    margin-top: 20px; /* Margin for spacing from other elements */
    box-sizing: border-box;
}

.featured-subjects, .qa-tutoring {
    padding: 20px;
}

/* Featured Subjects */
.featured-subjects h2 {
    font-size: 1.8em;
    margin-bottom: 20px;
}

.featured-subjects .ant-card {
    border-radius: 8px;
    overflow: hidden;
}

/* Q&A Tutoring */
.qa-tutoring h2 {
    font-size: 1.8em;
    margin-bottom: 20px;
}

.qa-tutoring .ant-card {
    border-radius: 8px;
}

/* Responsive Design */
@media (max-width: 767px) {
    .main-content {
        margin-top: 130px; /* Adjust margin for mobile view */
    }

    .featured-subjects h2, .qa-tutoring h2 {
        font-size: 1.5em;
    }
}
